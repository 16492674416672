import {ReducersMapObject} from '@reduxjs/toolkit';
import {loadingBarReducer as loadingBar} from 'react-redux-loading-bar';


import authentication from './authentication';
import country from './country';
import order from './order';
import password from "./password.reducer";
import subscriberPrepaidPackage from "./subscriberPrepaidPackages.reducer";
import register from './register.reducer';
import template from './template';
import subscriber from './subscriber.reducer';
import activate from './account/activate.reducer';
import passwordReset from "./account/password-reset.reducer";
import locale from "./locale";
import coupon from "./coupon";
// import exchangeRateReducer from "./exchangeRate.reducer";
// import languageReducer from "./language.reducer";
import popularCountriesReducer from "./popular-countries.reducer";
import userManagementReducer from "./user-management.reducer";
import qrOnboardReducer from "./qr-onboard.reducer";
import qrOnboardManagementReducer from "./qr-onboard-management.reducer";
import infoReducer from './info.reducer'

const rootReducer: ReducersMapObject = {
    template,
    register,
    authentication,
    order,
    subscriber,
    country,
    password,
    subscriberPrepaidPackage,
    activate,
    passwordReset,
    coupon,
    loadingBar,
    locale,
    userManagement: userManagementReducer,
    // exchangeRate: exchangeRateReducer,
    // language: languageReducer,
    popularCountries: popularCountriesReducer,
    qrOnboard: qrOnboardReducer,
    qrOnboardManagement: qrOnboardManagementReducer,
    info: infoReducer

};

export default rootReducer;
