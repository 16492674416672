import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";
import {ISubscriberUsage} from "../model/subscriber-usage.model";

export type subscriberPrepaidPackageState = Readonly<typeof subscriberPrepaidPackageInitialState>;

export const subscriberPrepaidPackageInitialState = {
    loading: false,
    subscriberPrepaidPackages: [] as ISubscriberUsage[],
};

export const listSubscriberPrepaidPackages = createAsyncThunk(
    'user/subscriberPrepaidPackage',
    async => axios.get<ISubscriberUsage[]>(`/api/user/plan`),
    {serializeError: serializeAxiosError}
);

export const SubscriberPrepaidPackageSlice = createSlice({
        name: 'plans',
        initialState: subscriberPrepaidPackageInitialState as subscriberPrepaidPackageState,
        reducers: {
            reset() {
                return subscriberPrepaidPackageInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(listSubscriberPrepaidPackages.pending, (state) => {
                    state.loading = true;
                })
                .addCase(listSubscriberPrepaidPackages.fulfilled, (state, action) => {
                    state.loading = false;
                    state.subscriberPrepaidPackages = action.payload.data;
                })
                .addCase(listSubscriberPrepaidPackages.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = SubscriberPrepaidPackageSlice.actions;

// Reducer
export default SubscriberPrepaidPackageSlice.reducer;