import axios from 'axios';
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';

import {IQueryParams, serializeAxiosError} from "./reducer.utils";
import {defaultValue, IQrOnboard} from "../../shared/model/qr-onboard.model";
import {ConstraintError} from "../../shared/model/constraint-error.model";
import {defaultIQRNotify, IQRNotify} from "../../shared/model/qr-notify.model";

const initialState = {
    loading: false,
    errorMessage: {} as ReadonlyArray<ConstraintError>,
    qrOnboards: [] as ReadonlyArray<IQrOnboard>,
    qrOnboard: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    notifyQROnboard: defaultIQRNotify,
};

const adminUrl = 'api/admin/qr-onboard';

export const getQROnboards = createAsyncThunk('QROnboardManagement/fetch_qr_onboard_as_admin', async ({
                                                                                                          page,
                                                                                                          size,
                                                                                                          sort,
                                                                                                          query
                                                                                                      }: IQueryParams) => {
    var requestUrl;
    if (query) {
        requestUrl = `${adminUrl}${sort ? `/search/${query}?page=${page}&size=${size}&sort=${sort}` : ''}`;
    } else {
        requestUrl = `${adminUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    }
    return axios.get<IQrOnboard[]>(requestUrl);
});


export const getQROnboard = createAsyncThunk(
    'QROnboardManagement/fetch_qr_onboard',
    async (id: string) => {
        const requestUrl = `${adminUrl}/${id}`;
        return axios.get<IQrOnboard>(requestUrl);
    },
    {serializeError: serializeAxiosError},
);

export const createQROnboard = createAsyncThunk(
    'userManagement/create_qr_onboard',
    async (user: IQrOnboard, thunkAPI) => {
        const result = await axios.post<IQrOnboard>(adminUrl, user);
        thunkAPI.dispatch(getQROnboards({}));
        return result;
    },
    {serializeError: serializeAxiosError},
);

export const notifyQROnboard = createAsyncThunk(
    'QROnboardManagement/notify_qr_onboard',
    async (id: string) => {
        const requestUrl = `${adminUrl}/${id}/notify`;
        return axios.get<IQRNotify>(requestUrl);
    },
    {serializeError: serializeAxiosError},
);

export const updateQROnboard = createAsyncThunk(
    'userManagement/update_qr_onboard',
    async (data: { id: string, user: IQrOnboard }, thunkAPI) => {
        const result = await axios.put<IQrOnboard>(adminUrl + `/${data.id}`, data.user);
        thunkAPI.dispatch(getQROnboards({}));
        return result;
    },
    {serializeError: serializeAxiosError},
);

export const deleteQROnboard = createAsyncThunk(
    'userManagement/delete_qr_onboard',
    async (id: string, thunkAPI) => {
        const requestUrl = `${adminUrl}/${id}`;
        const result = await axios.delete<IQrOnboard>(requestUrl);
        thunkAPI.dispatch(getQROnboards({}));
        return result;
    },
    {serializeError: serializeAxiosError},
);

export type QROnboardManagementState = Readonly<typeof initialState>;

export const QROnboardManagementSlice = createSlice({
    name: 'QROnboardManagement',
    initialState: initialState as QROnboardManagementState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder

            .addCase(getQROnboard.fulfilled, (state, action) => {
                state.loading = false;
                state.qrOnboard = action.payload.data;
            })
            .addCase(deleteQROnboard.fulfilled, state => {
                state.updating = false;
                state.updateSuccess = true;
                state.qrOnboard = defaultValue;
            })
            .addMatcher(isFulfilled(getQROnboards), (state, action) => {
                state.loading = false;
                state.qrOnboards = action.payload.data;
                state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
            })
            .addMatcher(isFulfilled(createQROnboard, updateQROnboard), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.qrOnboard = action.payload.data;
            })
            .addMatcher(isPending(getQROnboards, getQROnboard), state => {
                // state.errorMessage = undefined;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createQROnboard, updateQROnboard, deleteQROnboard), state => {
                // state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            })
            .addMatcher(isRejected(getQROnboards, getQROnboard, createQROnboard, updateQROnboard, deleteQROnboard), (state, action) => {
                state.loading = false;
                state.updating = false;
                state.updateSuccess = false;
                //@ts-ignore
                if (action.error.response) state.errorMessage = action.error.response.data;
            })
            .addMatcher(isFulfilled(notifyQROnboard), (state, action) => {
                state.notifyQROnboard = action.payload.data;
                state.loading = false;
            })
            .addMatcher(isPending(notifyQROnboard), state => {
                state.loading = true;
            })
            .addMatcher(isRejected(notifyQROnboard), (state, action) => {
                state.loading = false;
            });
    },
});

export const {reset} = QROnboardManagementSlice.actions;

// Reducer
export default QROnboardManagementSlice.reducer;
