import React, {FC, Fragment, useState} from "react";
import {Dialog, Transition} from "../../headlessui";
import ButtonClose from "./Button/ButtonClose";
import PlanQuickView from "./PlanQuickView";
import {IPlan} from "../../app/model/plan.model";
import {ValidatedField} from "react-jhipster";
import ButtonPrimary from "./Button/ButtonPrimary";
import coupon, {checkCoupon, reset} from "../../app/reducers/coupon";
import {listOrders} from "../../app/reducers/order";
import {useAppDispatch, useAppSelector} from "../../app/config/localStorage";


export interface ModalQuickViewProps {
    show: boolean;
    onCloseModalQuickView: () => void;
}

const VoucherModal: FC<ModalQuickViewProps> = ({
                                                   show,
                                                   onCloseModalQuickView,
                                               }) => {
    let dispatch = useAppDispatch();
    const [voucherCode, setVoucherCode] = useState('');
    const result: IPlan = useAppSelector(state => state.coupon.coupon);

    const checkVoucher = () => {
        // console.log('checkVoucher', voucherCode);
        dispatch(checkCoupon(voucherCode));
    }

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50"
                onClose={() => {onCloseModalQuickView(); dispatch(reset())}}
            >
                <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
                            <div
                                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                            >
                <span className="absolute end-3 top-3 z-50">
                  {/*<ButtonClose onClick={onCloseModalQuickView}/>*/}
                </span>

                                <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
                                    <label className="block relative px-3 py-4">
                                        <span className={"font-medium"}>Voucher</span>
                                        <ValidatedField
                                            name="voucher-code"
                                            type={'text'}
                                            value={voucherCode}
                                            onChange={(v) => setVoucherCode(v.target.value)}
                                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl placeholder-gray-300'}
                                            placeholder="Voucher Code"
                                        />

                                        <ButtonPrimary
                                            className={"bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 w-100"}
                                            type="submit"
                                            onClick={checkVoucher}
                                            disabled={false}
                                        >
                                            Buy</ButtonPrimary>
                                        {result.name}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default VoucherModal;
