import React from 'react';
import {translate} from "react-jhipster";

export interface PaginationProps {
    activePage: number;
    onSelect: (page: number) => void;
    maxButtons: number;
    totalItems: number;
    itemsPerPage: number;
}

export interface PaginationState {
    currentPage: number;
}

export class Pagination extends React.Component<PaginationProps, PaginationState> {
    constructor(props: PaginationProps | Readonly<PaginationProps>) {
        super(props);
        this.state = {
            currentPage: this.props.activePage,
        };
    }

    updateActivePage = (currentPage: number) => () => {
        this.setState({currentPage});
        this.props.onSelect(currentPage);
    };

    previousPage = () => {
        this.setState({currentPage: this.state.currentPage - 1});
        this.props.onSelect(this.state.currentPage - 1);
    };

    nextPage = () => {
        this.setState({currentPage: this.state.currentPage + 1});
        this.props.onSelect(this.state.currentPage + 1);
    };

    itemsToDisplay = (activePage: number) => {
        const items = [];
        let item: any = {};
        let previousItem: any = {};
        const maxPage = this.getMaxPage();
        const padSup = Math.floor((this.props.maxButtons - 1) / 2);
        const modulo = (this.props.maxButtons - 1) % 2;
        const padInf = padSup + modulo;
        for (let j = 0; j < maxPage; j++) {
            item = {};
            if (
                j === 0 ||
                j === maxPage - 1 ||
                j === activePage - 1 ||
                j === activePage - 2 ||
                (activePage === 1 && j === 1) ||
                (activePage - padInf < j && j < activePage + padSup)
            ) {
                item.display = 'display';
            } else if (previousItem.display === 'disabled') {
                item.display = 'hidden';
            } else {
                item.display = 'disabled';
            }
            items.push(item);
            previousItem = {...item};
            if (item.display === 'hidden') {
                previousItem.display = 'disabled';
            }
        }
        return items;
    };

    displayPaginationItem = (i: number, activePage: number) => (
        // <PaginationItem {...(activePage === i + 1 && {active: true})} key={i}>
        //     <PaginationLink onClick={this.updateActivePage(i + 1)}>{i + 1}</PaginationLink>
        // </PaginationItem>
        <li key={`page-${i}`}>
            <a onClick={this.updateActivePage(i + 1)}
               className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 " +
                   "hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${activePage === i + 1 && { active: true }? 'disabled' : ''}`}>{i + 1}</a>
        </li>
    );

    cleanActivePage = () => {
        const {totalItems, itemsPerPage, activePage} = this.props;
        const cleanActivePage = totalItems === 0 ? 1 : Math.min(activePage, Math.ceil(totalItems / itemsPerPage));

        if (cleanActivePage !== activePage) {
            this.updateActivePage(cleanActivePage)();
        }
    };

    getMaxPage = () => {
        const {itemsPerPage, totalItems} = this.props;
        const division = Math.floor(totalItems / itemsPerPage);
        const modulo = totalItems % itemsPerPage;
        return division + (modulo !== 0 ? 1 : 0);
    };

    render() {
        this.cleanActivePage();
        const {activePage} = this.props;
        const maxPage = this.getMaxPage();

        return (
            <nav className={"flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"}
                 aria-label="Table navigation">
             <span
                 className={"text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto"}>{translate("table.showing")} <span
                 className={"font-semibold text-gray-900 dark:text-white"}>
                 {(this.props.activePage - 1) * this.props.itemsPerPage === 0 ? 1 : (this.props.activePage - 1) * this.props.itemsPerPage + 1} -{' '}
                 {this.props.activePage * this.props.itemsPerPage < this.props.totalItems ? this.props.activePage * this.props.itemsPerPage : this.props.totalItems}
             </span> {translate("table.of")} <span
                 className={"font-semibold text-gray-900 dark:text-white"}>{this.props.totalItems}</span></span>
                <ul className={"inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 cursor-pointer"}>
                    <li key={'page-first'}>
                        <a onClick={this.updateActivePage(1)}
                           className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border " +
                               "border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${activePage === 1 && {disabled: true} ? 'disabled' : ''}`}>{translate("global.pagination.first")}</a>
                    </li>
                    <li key={'page-previousPage'}>
                        <a onClick={this.previousPage}
                           className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 " +
                               "dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${activePage === 1 && {disabled: true} ? 'disabled' : ''}`}>{translate("global.pagination.previous")}</a>
                    </li>
                    {this.itemsToDisplay(activePage).map((paginationItem, i) =>
                        paginationItem.display === 'display' ? (
                            this.displayPaginationItem(i, activePage)
                        ) : paginationItem.display === 'disabled' ? (
                            <li key={`page-${i}`}>
                                <a onClick={this.previousPage}
                                   className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 " +
                               "dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled`}>...</a>
                            </li>
                        ) : null
                    )}
                    <li key={'page-next'}>
                        <a onClick={this.nextPage}
                           className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 " +
                               "dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${activePage === maxPage ? 'disabled' : ''}`}>{translate("global.pagination.next")}</a>
                    </li>
                    <li key={'page-last'}>
                        <a onClick={this.updateActivePage(maxPage)}
                           className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border 
                           border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${activePage === maxPage && { disabled: true } ? 'disabled' : ''}`}>{translate("global.pagination.last")}</a>
                    </li>
                </ul>
            </nav>

        )
            ;
    }
}
