import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";
import {IPlan} from "../model/plan.model";
import {defaultCouponValue, ICoupon} from "../model/coupon.model";

export type CouponState = Readonly<typeof couponInitialState>;

export const couponInitialState = {
    loading: false,
    coupon:  defaultCouponValue as ICoupon,
    isError: false
};


export const checkCoupon = createAsyncThunk(
    'coupon/check',
    async(code: string) => axios.get<ICoupon>(`/api/coupon/${code}`),
    {serializeError: serializeAxiosError}
);

export const CouponSlice = createSlice({
        name: 'coupon',
        initialState: couponInitialState as CouponState,
        reducers: {
            reset() {
                return couponInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(checkCoupon.pending, (state) => {
                    state.loading = true;
                })
                .addCase(checkCoupon.fulfilled, (state, action) => {
                    state.loading = false;
                    state.isError = false;
                    state.coupon = action.payload.data;
                })
                .addCase(checkCoupon.rejected, (state, action) => {
                    state.loading = false;
                    state.isError = true;
                })
            ;
        },
    })
;

export const {reset} = CouponSlice.actions;

// Reducer
export default CouponSlice.reducer;