import React from "react";
import NavigationItem, {NavItemType} from "./NavigationItem";
import ncNanoId from "../utils/ncNanoId";
import {useAppState} from "../../app/config/AppStateContext";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "../../app/config/localStorage";
import {AUTHORITIES} from "../../app/config/constants";
import {hasAnyAuthority} from "../components/PrivateRoute/private-route";
import {translate} from "react-jhipster";

export const NAVIGATION_MENU: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/",
        name: 'global.menu.home',
    },
    {
        id: ncNanoId(),
        href: "/faq/first-use",
        name: "global.menu.first-use",
    },
    {
        id: ncNanoId(),
        href: "/esim",
        name: "global.menu.about",
    },
    {
        id: ncNanoId(),
        href: "/contact",
        name: "global.menu.contact",
    },
    {
        id: ncNanoId(),
        href: "/",
        name: "global.menu.administration",
        type: "dropdown",
        roles: [AUTHORITIES.ADMIN],
        children: [
            {
                id: ncNanoId(),
                href: "/admin/users",
                name: "global.menu.user-management",
                roles: [AUTHORITIES.ADMIN],
            },
            {
                id: ncNanoId(),
                href: "/admin/onboarding",
                name: "global.menu.qr-management",
                roles: [AUTHORITIES.ADMIN],
            }
        ],
    },

];
export const getPageTitle = (pathname: string): string => {
    switch (pathname) {
        case '/':
            return 'global.title.destination';
        case '/esim':
            return 'global.title.esim';
        case '/faq/first-use':
            return 'global.title.first-use';
        case '/contact':
            return 'global.title.contact';
    }

    return pathname
        .split('/')
        .filter(Boolean)
        .filter((part) => !part.includes('prod'))
        .map((part) =>
            part
                .replace(/-/g, ' ')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        )
        .join(' | ');
};

function Navigation() {
    const {state} = useAppState();
    const location = useLocation()
    const account = useAppSelector(state => state.authentication.account);
    const translation = translate(getPageTitle(location.pathname));
    let suffix;
    if (translation && translation.includes("translation-not-found")) {
        suffix = '';
    } else {
        suffix = ' | ' + translation
    }
    document.title = state.tenant.name + suffix;

    return (
        <ul className="nc-Navigation flex items-center">
            {NAVIGATION_MENU
                .filter(menu => {
                    if (!menu.roles) {
                        return menu;
                    } else {
                        const isAuthorized = hasAnyAuthority(account.authorities, menu.roles);
                        if (isAuthorized) {
                            return menu;
                        }
                    }

                })
                .map((item) => (
                    <NavigationItem key={item.id} menuItem={item}/>
                ))}
        </ul>
    );
}

export default Navigation;
