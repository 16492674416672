import React, {FC, useEffect} from 'react';
import {useAppState} from "../../app/config/AppStateContext";
import {translate} from "react-jhipster";

export interface IAmountProps {
    amount: number;
    currency: string;
    vatIncluded?: boolean,
}

const Amount: FC<IAmountProps> = ({amount, currency, vatIncluded = false}) => {
    const {state} = useAppState();

    const formatCurrency = (amount: number, currency: string): string => {
        console.log("formatCurrency", amount, currency);
        //check for null or undefined currency
        if (!currency) {
            return '';
        }
        if (currency === 'huf') {
            // Custom formatting for Hungarian Forint
            return new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(amount).replace(/,/g, ' ');
        } else {
            // Default formatting
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
            }).format(amount);
        }
    };

    return (
        <>
            {formatCurrency(amount, currency)}
            {(state.tenant.taxPercentage !== 0 && !vatIncluded) ? (
                <sup className=""> &nbsp; + {translate("global.text.vat")}</sup>
            ) : null}
        </>
    );
};

export default Amount;
