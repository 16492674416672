import {Dialog, Transition} from "../../headlessui";
import React, {FC, Fragment} from "react";
import LegalComponent from "../LegalComponent";
import {useAppState} from "../config/AppStateContext";
import ButtonClose from "../../shared/components/Button/ButtonClose";
import PlanQuickView from "../../shared/components/PlanQuickView";
import LegalHero from "../../shared/esim/LegalHero";
import {translate} from "react-jhipster";
import CookiePolicy from "../CookiePolicy";

export interface ModalViewLegalProps {
    show: boolean;
    onCloseModalViewLegal: () => void;
    value: string;
}

const ModalViewLegal: FC<ModalViewLegalProps> = ({
                                                     show, onCloseModalViewLegal, value
                                                 }) => {
    const {state} = useAppState();

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-scroll"
                onClose={onCloseModalViewLegal}
                onClick={onCloseModalViewLegal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-500"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-500"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className=""
                        aria-hidden="true"
                    >
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full pt-24 pb-24 max-w-5xl ">


                            <div
                                className="overflow-visible p-8 w-full text-left align-middle transition-all transform rounded-2xl bg-white
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                            >
                <span className="absolute end-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalViewLegal}/>
                </span>

                                <div className="overflow-visible">

                                    {value === "state.tenant.documents.termsAndConditions" ? (
                                        <LegalHero
                                            heading={translate("legal.terms-and-conditions.title")}
                                            subHeading={translate("legal.terms-and-conditions.content")}
                                            functionToCall="window.location.href='/';"
                                        />
                                    ) : value === "state.tenant.documents.privacyPolicy" ? (
                                        <LegalHero
                                            heading={translate("legal.privacy-policy.title")}
                                            subHeading={translate("legal.privacy-policy.content")}
                                            functionToCall="window.location.href='/';"
                                        />
                                    ) : value === "state.tenant.documents.cookiePolicy" ? (
                                        <CookiePolicy
                                            // heading={translate("legal.cookie-policy.title")}
                                            // subHeading={translate("legal.cookie-policy.content")}
                                            // functionToCall="window.location.href='/';"
                                        />
                                    ) : null}


                                    {/*<LegalComponent*/}

                                    {/*    className={""}*/}
                                    {/*    document={eval(value)}*/}
                                    {/*/>*/}
                                </div>
                            </div>


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalViewLegal;
