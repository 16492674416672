import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {translate, ValidatedField, ValidatedForm} from 'react-jhipster';
import {useAppDispatch, useAppSelector} from "../../config/localStorage";
import {createQROnboard, getQROnboard, reset, updateQROnboard} from "../../reducers/qr-onboard-management.reducer";
import ButtonPrimary from "../../../shared/components/Button/ButtonPrimary";
import {FieldError, useForm} from "react-hook-form";
import {ICountry} from "../../model/country";
import {findCountry} from "../../reducers/country";
import {IPlan} from "../../model/plan.model";
import {findTemplates} from "../../reducers/template";
import LoadingSpinner from "../../LoadingSpinner";
import {ConstraintError} from "../../../shared/model/constraint-error.model";
import {IQrOnboard} from "../../../shared/model/qr-onboard.model";

export const FullQROnboardManagementUpdate = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const qrOnboard: IQrOnboard = useAppSelector(state => state.qrOnboardManagement.qrOnboard);
    const loading = useAppSelector(state => state.qrOnboardManagement.loading);
    const countries: ICountry[] = useAppSelector(state => state.country.countries);
    const templates: IPlan[] = useAppSelector(state => state.template.templates);
    const errorMessage: ConstraintError = useAppSelector(state => state.qrOnboardManagement.errorMessage);
    const updateSuccess: ConstraintError = useAppSelector(state => state.qrOnboardManagement.updateSuccess);

    const {id} = useParams<'id'>();
    const isNew = id === undefined;
    const {
        handleSubmit,
        register,
        setError,
        reset: resetForm,
        formState: {errors},
    } = useForm({mode: 'onTouched'});
    const title = isNew ? 'onboard-management.new.title' : 'onboard-management.edit.title';
    const submitButton = isNew ? 'onboard-management.new.button.submit' : 'onboard-management.edit.button.submit';


    useEffect(() => {
        dispatch(findCountry(''));
        dispatch(findTemplates());
        if (isNew) {
            dispatch(reset());
        } else {
            dispatch(getQROnboard(id));
            reset();
        }
        return () => {
            dispatch(reset());
        };
    }, [id]);

    useEffect(() => {
        resetForm(qrOnboard);
    }, [qrOnboard]);

    useEffect(() => {
        if (updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    useEffect(() => {
        errorMessage.fieldErrors?.forEach((error) => {
            setError(error.field, {message: translate(error.message)})
        })
    }, [errorMessage]);

    const handleClose = () => {
        navigate('/admin/onboarding');
    };

    const saveUser = (values: any) => {
        console.log(values);
        if (isNew) {
            dispatch(createQROnboard(values));
        } else {
            dispatch(updateQROnboard({id: id, user: values}));
        }
        // handleClose();
    };

    // @ts-ignore
    return (
        <div className="container">
            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 mt-12 mb-12"><h2
                className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">{translate(title)}</h2>
                {loading ? <LoadingSpinner/> :
                    <ValidatedForm
                        //@ts-ignore
                        onSubmit={handleSubmit(saveUser)}>
                        <div className={`grid lg:grid-cols-2 gap-5 xl:gap-8`}>
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    register={register}
                                    error={errors.email as FieldError}
                                    name="email"
                                    label={translate("global.email-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.email-placeholder")}
                                    type="email"
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    validate={{
                                        required: {value: true, message: translate("global.error.email-required")},
                                        minLength: {
                                            value: 1,
                                            message: translate("global.email.email-length-min")
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: translate("global.email.email-length-max")
                                        },
                                    }}
                                    data-cy="email"
                                />
                            </div>
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    register={register}
                                    error={errors.name as FieldError}
                                    name="name"
                                    label={translate("global.name-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.name-placeholder")}
                                    type="text"
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    validate={{
                                        required: {value: true, message: translate("global.error.name-required")},
                                        minLength: {
                                            value: 1,
                                            message: translate("global.error.name-length-min")
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: translate("global.error.name-length-max")
                                        },
                                    }}
                                    data-cy="name"
                                />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-2 gap-5 xl:gap-8">

                            <div className="relative px-3 py-4">
                                {countries ?
                                    <ValidatedField
                                        type="select"
                                        register={register}
                                        //@ts-ignore
                                        error={errors?.address?.country}
                                        data-cy="address.country"
                                        label={translate("global.country-label")}
                                        labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                        placeholder={translate("global.country-placeholder")}
                                        inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                        name="address.country"
                                        validate={{
                                            value: {value: true, message: translate("global.error.country-required")},
                                            required: {
                                                value: true,
                                                message: translate("global.error.country-required")
                                            },
                                        }}>
                                        <option></option>
                                        {countries.map(c => (
                                            <option value={c.countryIso2} key={c.countryIso2}>
                                                {translate(`global.countries.${c.countryIso2}`)}
                                            </option>
                                        ))}
                                    </ValidatedField>
                                    : null}
                            </div>

                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.address?.city}
                                    name="address.city"
                                    type="text"
                                    label={translate("global.city-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.city-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    data-cy="address.city"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate("global.error.city-required")
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-2 gap-5 xl:gap-8">
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.address?.state}
                                    name="address.state"
                                    type="text"
                                    label={translate("global.state-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.state-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    data-cy="address.state"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate("global.error.state-required")
                                        },
                                    }}
                                />
                            </div>
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.address?.postalCode}
                                    name="address.postalCode"
                                    type="text"
                                    label={translate("global.postal-code-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.postal-code-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    data-cy="address.postalCode"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate("global.error.postalCode-required")
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-1 gap-5 xl:gap-8">
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    //@ts-ignore
                                    error={errors?.address?.address}
                                    register={register}
                                    name="address.address"
                                    type="text"
                                    label={translate("global.address-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.address-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    data-cy="address.address"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate("global.error.address-required")
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-1 gap-5 xl:gap-8">
                            <div className="relative px-3 py-4">
                                <ValidatedField
                                    //@ts-ignore
                                    error={errors?.phoneNumber}
                                    register={register}
                                    name="phoneNumber"
                                    type="text"
                                    label={translate("global.phone-label")}
                                    labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                    placeholder={translate("global.phone-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    data-cy="phoneNumber"
                                    validate={{
                                        pattern: {
                                            value: /^[\+\(\s.\-\/\d\)]{5,30}$/,
                                            message: translate("global.error.phoneNumber-invalid")
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-1 gap-5 xl:gap-8">
                            <div className="relative px-3 py-4">
                                {templates ?
                                    <ValidatedField
                                        type="select"
                                        register={register}
                                        //@ts-ignore
                                        error={errors?.productId}
                                        data-cy="productId"
                                        label={translate("global.product-label")}
                                        labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                        placeholder={translate("global.product-placeholder")}
                                        inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                        name="productId"
                                        validate={{
                                            value: {value: true, message: translate("global.error.country-required")},
                                            required: {
                                                value: true,
                                                message: translate("global.error.country-required")
                                            },
                                        }}>
                                        <option></option>
                                        {templates.map(t => (
                                            <option value={t.id} key={t.id}>
                                                {t.name}
                                            </option>
                                        ))}
                                    </ValidatedField>
                                    : null}
                            </div>
                        </div>

                        <ButtonPrimary
                            className={'buyCardButton mt-3 bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white'}
                            type="submit"
                            disabled={loading}>{translate(submitButton)}</ButtonPrimary>
                    </ValidatedForm>

                }
            </div>
        </div>
    );
};

export default FullQROnboardManagementUpdate;
