import {TranslatorContext, Storage} from 'react-jhipster';
import {setLocale, updateLocale} from "../reducers/locale";

TranslatorContext.setDefaultLocale('en');
TranslatorContext.setRenderInnerTextForMissingKeys(false);

//@ts-ignore
export const registerLocale = (store, defaultLocale: string) => {
    store.dispatch(setLocale(Storage.local.get('locale', defaultLocale)));
};
