import React, {Fragment, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../config/localStorage";
import {deleteQROnboard, getQROnboard} from "../../reducers/qr-onboard-management.reducer";
import {Dialog, Transition} from "../../../headlessui";
import ButtonClose from "../../../shared/components/Button/ButtonClose";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import {Translate} from "react-jhipster";

export const OnboardManagementQRViewDialog = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const {id} = useParams<'id'>();

    useEffect(() => {
        if (id != null) {
            dispatch(getQROnboard(id));
        }
    }, []);

    const handleClose = (event: any) => {
        navigate('/admin/onboarding');
    };

    const qrOnboard = useAppSelector(state => state.qrOnboardManagement.qrOnboard);

    const confirmDelete = (event: any) => {
        dispatch(deleteQROnboard(qrOnboard.id));
        handleClose(event);
    };

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => handleClose('')}
            >
                <div className="min-h-screen px-1 text-center md:px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {id ?
                            <div
                                className={`inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 max-w-screen-sm`}
                            >
                                <div
                                    className="py-4 px-6 text-center relative md:py-5">
                                    <ButtonClose
                                        onClick={() => handleClose('')}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 sm:left-4"
                                    />
                                </div>
                                <div
                                    className={'py-4 px-6 flex items-stretch md:items-center justify-center h-full text-center '}>
                                    <form action="#">
                                        <div className="mt-4 space-x-3">
                                            <img
                                                style={{display: "inline", marginRight: "4px"}}
                                                alt={qrOnboard.id}
                                                src={`/api/admin/qr-onboard/${qrOnboard.id}/view`}
                                                className={'w-1/1'}
                                            />
                                            <div className={'mt-4 space-x-3'}>
                                                <ButtonSecondary
                                                    type="button"
                                                    onClick={() => {
                                                        handleClose('')
                                                    }}>
                                                    <Translate contentKey={'onboard-management.view.button.close'}/>
                                                </ButtonSecondary>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : null}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default OnboardManagementQRViewDialog;
