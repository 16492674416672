import React from "react";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import SectionHero from "../shared/esim/SectionHero";
import SectionStatistic from "./SectionStatistic";
import {translate} from "react-jhipster";
import {useNavigate} from "react-router";


const About = ({}) => {
    const navigate = useNavigate();
    return (
        <div className={`nc-PageAbout overflow-hidden relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>

            <div className="container mb-12">
                <SectionHero
                    rightImg={'/cdn/content/about.png'}
                    heading={translate("esim.title")}
                    btnText={translate("esim.see-offers")}
                    // subHeading="An eSIM is an electronic SIM that allows you to activate cellular plans without inserting a physical SIM card into your device. It enables you to seamlessly switch between carriers, optimizing roaming costs for enhanced convenience and cost-effectiveness."
                    subHeading={translate("esim.heading")}
                    subHeading2=""
                    functionToCall="window.location.href='/';"
                    navigation={() => navigate('/')}
                />
                <SectionStatistic/>
            </div>
        </div>
    );
};

export default About;
