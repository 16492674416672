import {Observable} from "rxjs";
import SockJS from 'sockjs-client';
import Stomp, {Client, Frame, Subscription} from 'webstomp-client';
import {Storage} from "../reducers/storage-util";
import {
    AUTH_TOKEN_KEY,
    getUser,
    impersonate,
    IMPERSONATE_AUTH_TOKEN_KEY,
    logoutSession
} from "../reducers/authentication";
import {listSubscriber} from "../reducers/subscriber.reducer";
import {defaultValue, IUser} from "../model/user.model";
import {IAction, IEntity, IPrivateNotification} from "../model/private-notification.model";

let stompClient: Client | null = null;

let subscriber: Subscription | undefined = undefined;
let connection: Promise<any>;
let connectedPromise: any = null;
let listener: Observable<any>;
let listenerObserver: any;
let alreadyConnectedOnce = false;
let account: IUser = defaultValue;
const createConnection = (): Promise<any> => new Promise(resolve => (connectedPromise = resolve));

const createListener = (): Observable<any> =>
    new Observable((observer: any) => {
        listenerObserver = observer;
    });

const subscribe = (account: any) => {
    connection.then(() => {
        subscriber = stompClient?.subscribe(`/private/${account.privateNotificationChannel}`, (data: {
            body: string;
        }) => {
            listenerObserver.next(JSON.parse(data.body));
        });
    }).catch(() => {
        console.log('error case')
    });
};

const connect = () => {
    if (connectedPromise !== null || alreadyConnectedOnce) {
        // console.log('the connection is already being established');
        return;
    }
    connection = createConnection();
    listener = createListener();

    // const baseHref = window.location.href.replace(/\/$/, '');

    const headers = {};
    const loc = window.location;
    let baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');
    if (!baseHref) {
        baseHref = ''
    }

    let url = '//' + loc.host + baseHref + '/websocket/private';
    const authToken = Storage.local.get(AUTH_TOKEN_KEY);
    const impersonateAuthToken = Storage.local.get(IMPERSONATE_AUTH_TOKEN_KEY);

    if (impersonateAuthToken) {
        url += '?access_token=' + impersonateAuthToken;
    } else {
        if (authToken) {
            url += '?access_token=' + authToken;
        }
    }


    const socket = new SockJS(url);
    stompClient = Stomp.over(socket, {protocols: ['v12.stomp']});
    stompClient.connect(headers, () => {
        // console.log('connected');
        connectedPromise('success');
        connectedPromise = null;
        alreadyConnectedOnce = true;
    }, (error: Frame | CloseEvent) => {
        if ('type' in error && error.type === "close") {
            alreadyConnectedOnce = false;
            connectedPromise = null;
            setTimeout(connectAndSubscribe, 1000);
        }
    });
};

const connectAndSubscribe = () => {
    connect();
    subscribe(account)
}

const disconnect = () => {
    if (stompClient !== null) {
        if (stompClient.connected) {
            stompClient.disconnect();
        }
        stompClient = null;
    }
    alreadyConnectedOnce = false;
};

const receive = () => listener;

const unsubscribe = () => {
    if (subscriber !== null) {
        subscriber?.unsubscribe();
    }
    listener = createListener();
};

//@ts-ignore
export default store => next => action => {
    if(impersonate.fulfilled.match(action)){
        unsubscribe();
        disconnect();
    }

    if (getUser.fulfilled.match(action)) {
        account = action.payload.data;
        connect();
        if (!alreadyConnectedOnce) {
            subscribe(action.payload.data);
            receive().subscribe((notification: IPrivateNotification) => {
                switch (notification.action) {
                    case IAction.RELOAD: {
                        switch (notification.entity) {
                            case IEntity.SUBSCRIBER:
                                return store.dispatch(listSubscriber())
                        }
                    }
                }
            });
        }
    } else if (getUser.rejected.match(action) || action.type === logoutSession().type) {
        unsubscribe();
        disconnect();
    }

    return next(action);
};
