import React from "react";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import LegalComponent from "./LegalComponent";
import {useAppState} from "./config/AppStateContext";
import LegalHero from "../shared/esim/LegalHero";
import {translate} from "react-jhipster";
import {Viewer} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {Worker} from '@react-pdf-viewer/core';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const TermsAndConditions = ({}) => {
    const {state} = useAppState();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div className={`nc-PageAbout overflow-hidden relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>
            <div className="container mt-12 mb-12">
                <LegalHero
                    heading={translate("legal.terms-and-conditions.title")}
                    subHeading={translate("legal.terms-and-conditions.content")}
                    functionToCall="window.location.href='/';"
                />
            </div>
            <div className="container mb-12">
                {
                    state.tenant.url !== 'www.utazosim.hu' && state.tenant.url !== 'www.advanced-sim.com' ?
                        <LegalComponent
                            className={""}
                            document={state.tenant.documents.termsAndConditions}
                        />

                        :
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer
                                fileUrl={'/cdn/content/terms-and-conditions.pdf'}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                        </Worker>

                }


            </div>
        </div>
    );
};

export default TermsAndConditions;
