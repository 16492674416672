import React, {FC, ReactNode} from "react";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import {useAppState} from "../../app/config/AppStateContext";

export interface SectionHeroProps {
    className?: string;
    rightImg: string;
    heading: ReactNode;
    subHeading: string;
    subHeading2: string;
    btnText: string;
    functionToCall: string
    rightVideoUrl?: string;
    navigation?: (() => void)
}

const SectionHero: FC<SectionHeroProps> = ({
                                               className = "",
                                               rightImg,
                                               heading,
                                               subHeading,
                                               subHeading2,
                                               btnText,
                                               functionToCall,
                                               rightVideoUrl,
                                               navigation

                                           }) => {
    const hrefString = `javascript:${functionToCall}`;
    const {state} = useAppState();

    return (
        <div
            className={`nc-SectionHero relative ${className}`}
            data-nc-id="SectionHero"
        >
            <div
                className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
                <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
                    <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100"
                        style={{color: state.tenant.primaryColor}}
                    >
                        {heading}
                    </h2>
                    <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
                    {btnText ?
                    !navigation ?
                        <ButtonPrimary
                            className={"buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"}
                            href={hrefString}
                        >{btnText}</ButtonPrimary> :
                        <ButtonPrimary
                            className={"buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"}
                            onClick={navigation}
                        >{btnText}</ButtonPrimary>
                    : null
                    }
                </div>
                <div className="flex-grow">
                    {/*    if rightVideoUrl is not null then video tag */}
                    {rightVideoUrl ? (
                        <div className="relative rounded-2xl p-12 ">
                            <video
                                className="relative rounded-2xl "
                                autoPlay
                                loop
                                muted
                                playsInline
                                controls={true}
                            >
                                <source src={rightVideoUrl} type="video/mp4"/>
                            </video>
                        </div>
                    ) : (
                        <img
                            src={rightImg}
                            style={{maxHeight: "400px", objectFit: "contain"}}
                            alt="hero"
                            className="w-full h-full object-cover rounded-3xl"

                        />
                    )}

                </div>
            </div>
        </div>
    );
};

export default SectionHero;
