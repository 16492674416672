import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from "./reducer.utils";
import {REGISTER_MESSAGES_SUCCESS} from "../config/MessageConstants";


const initialState = {
    loading: false,
    registrationSuccess: false,
    registrationFailure: false,
    errorMessage: null,
    successMessage: null,
};

export type RegisterState = Readonly<typeof initialState>;

export const handleRegister = createAsyncThunk(
    'register/create_account',
    async (data: {
        login: string;
        email: string;
        name: string;
        password: string;
        langKey?: string
    }) => axios.post<any>('api/register', data),
    {serializeError: serializeAxiosError},
);

export const RegisterSlice = createSlice({
    name: 'register',
    initialState: initialState as RegisterState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers(builder) {
        //@ts-ignore
        builder
            .addCase(handleRegister.pending, state => {
                state.loading = true;
            })
            .addCase(handleRegister.rejected, (state, action) => ({
                ...initialState,
                registrationFailure: true,
                errorMessage: action.error.message,
            }))
            .addCase(handleRegister.fulfilled, () => ({
                ...initialState,
                registrationSuccess: true,
                successMessage: REGISTER_MESSAGES_SUCCESS,
            }));
    },
});

export const {reset} = RegisterSlice.actions;

// Reducer
export default RegisterSlice.reducer;
