import React, {FC} from "react";
import Amount from "../Amount";

export interface PricesProps {
    className?: string;
    price?: number;
    currency?: string;
    contentClass?: string;
    vatIncluded?: boolean;
}

const Prices: FC<PricesProps> = ({
                                     className = "",
                                     price = 33,
                                     currency = "usd",
                                     contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
                                     vatIncluded = true,
                                 }) => {
    return (
        <div className={`${className}`}>
            <div
                className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}
            >
        <span className="text-green-500 !leading-none">

             <Amount amount={price}
                     currency={currency}
                     vatIncluded={vatIncluded}
             />

        </span>
            </div>
        </div>
    );
};

export default Prices;
