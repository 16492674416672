import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";

export type InfoState = Readonly<typeof infoInitialState>;

export const infoInitialState = {
    versionLoading: false,
    version: {} as any,
};

export const getVersion = createAsyncThunk(
    'info/version',
    async () => axios.get<any>(`/api/version`),
    {serializeError: serializeAxiosError}
);

export const InfoSlice = createSlice({
        name: 'info',
        initialState: infoInitialState as InfoState,
        reducers: {
            reset() {
                return infoInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(getVersion.pending, (state) => {
                    state.versionLoading = true;
                })
                .addCase(getVersion.fulfilled, (state, action) => {
                    state.versionLoading = false;
                    state.version = action.payload.data;
                })
                .addCase(getVersion.rejected, (state, action) => {
                    state.versionLoading = false;
                })
            ;
        },
    })
;

export const {reset} = InfoSlice.actions;

// Reducer
export default InfoSlice.reducer;