import React from "react";
// import ButtonClose from "@/shared/ButtonClose/ButtonClose";
// import Logo from "@/shared/Logo/Logo";
import {Disclosure} from "../../headlessui";
import Logo from "./Logo";
import {Link, useLocation} from "react-router-dom";
import {NAVIGATION_MENU} from "../navigation/Navigation";
import {NavItemType} from "../navigation/NavigationItem";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import SocialsList from "../components/SocialsList/SocialsList";
import ButtonClose from "../components/Button/ButtonClose";
import {translate} from "react-jhipster";
import {useAppState} from "../../app/config/AppStateContext";


export interface INavMobileProps {
    data?: any[];
    onClickClose?: () => void;
}

const NavMobile = (props: INavMobileProps) => {
    const {state} = useAppState();
    const location = useLocation();
    const _renderMenuChild = (
        item: any,
        itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
    ) => {
        return (
            <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">

                {//@ts-ignore
                    item.children?.map((i, index) => (
                        <Disclosure key={index} as="li">
                            <div
                                className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                            >
              <span
                  className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                  onClick={props.onClickClose}
              >
                {i.name}
              </span>
                                {i.children && (
                                    <span
                                        className="flex items-center flex-grow"
                                        onClick={(e) => e.preventDefault()}
                                    >
                  <Disclosure.Button
                      as="span"
                      className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-slate-500"
                        aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
                                )}
                            </div>
                            {i.children && (
                                <Disclosure.Panel>
                                    {/*{_renderMenuChild(*/}
                                    {/*  i,*/}
                                    {/*  "pl-3 text-slate-600 dark:text-slate-400 "*/}
                                    {/*)}*/}
                                </Disclosure.Panel>
                            )}
                        </Disclosure>
                    ))}
            </ul>
        );
    };

    const _renderItem = (item: NavItemType, index: number) => {
        const isCurrentPage = location.pathname === (item.href || '');
        if(item.type === 'dropdown') {
            return null;
        }
        return (
            <Disclosure
                key={index}
                as="li"
                className="text-slate-900 dark:text-white"
            >
                <Link
                    className={`flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg
           ${isCurrentPage ? 'text-slate-900 bg-slate-100 dark:bg-slate-800 dark:text-slate-200' : ''}`}
                    to={{
                        pathname: item.href || undefined,
                    }}
                >
          <span
              className={!item.children ? "block w-full" : ""}
              onClick={props.onClickClose}
          >
            {translate(item.name)}
          </span>
                    {item.children && (
                        <span
                            className="block flex-grow"
                            onClick={(e) => e.preventDefault()}
                        >
              <Disclosure.Button
                  as="span"
                  className="flex justify-end flex-grow"
              >
                {/*<ChevronDownIcon*/}
                  {/*  className="ml-2 h-4 w-4 text-neutral-500"*/}
                  {/*  aria-hidden="true"*/}
                  {/*/>*/}
              </Disclosure.Button>
            </span>
                    )}
                </Link>
                {item.children && (
                    <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
                )}
            </Disclosure>
        );
    };

    // const renderMagnifyingGlassIcon = () => {
    //   return (
    //     <svg
    //       width={22}
    //       height={22}
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
    //         stroke="currentColor"
    //         strokeWidth="1.5"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M22 22L20 20"
    //         stroke="currentColor"
    //         strokeWidth="1.5"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   );
    // };

    // const renderSearchForm = () => {
    //   return (
    //     <form
    //       action=""
    //       method="POST"
    //       className="flex-1 text-slate-900 dark:text-slate-200"
    //     >
    //       <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl h-full">
    //         {renderMagnifyingGlassIcon()}
    //         <input
    //           type="search"
    //           placeholder="Type and press enter"
    //           className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
    //         />
    //       </div>
    //       <input type="submit" hidden value="" />
    //     </form>
    //   );
    // };


    return (
        <div
            className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
            <div className="py-6 px-5">
                <Logo/>
                <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
           <span>
{
    state.tenant.longFormDescription
}          </span>
                    <div className="flex justify-between items-center mt-4">
                        <SocialsList
                            itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl"/>
                        <span className="block">
              {/*<SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />*/}
            </span>
                    </div>
                </div>
                <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={props.onClickClose}/>
        </span>

            </div>
            <ul className="flex flex-col py-6 px-2 space-y-1">
                {NAVIGATION_MENU.map(_renderItem)}
            </ul>

        </div>
    );
};

export default NavMobile;
