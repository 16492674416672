import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Translate, translate} from 'react-jhipster';

import {useAppDispatch, useAppSelector} from "../../config/localStorage";
import {ASC, DESC, ITEMS_PER_PAGE, SORT} from "../../config/constants";
import {overridePaginationStateWithQueryParams} from "../../config/entity-utils";
import {getUsersAsAdmin} from "../../reducers/user-management.reducer";
import {Pagination} from "../../../shared/utils/PaginationProps";
import DynamicHeroIcon from "../../../shared/utils/DynamicHeroIcon";
import DateFormat from "../../../shared/utils/DateFormatUtils";
import {impersonateUser} from "../../reducers/authentication";
import {getPaginationState} from "../../config/pagination-utils";
import {IUser} from "../../../shared/model/user.model";

export const UserManagement = () => {
    const dispatch = useAppDispatch();

    const pageLocation = useLocation();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState(
        //@ts-ignore
        overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'login'), pageLocation.search),
    );

    const getUsersFromProps = () => {
        dispatch(
            getUsersAsAdmin({
                page: pagination.activePage - 1,
                size: pagination.itemsPerPage,
                sort: `${pagination.sort},${pagination.order}`,
                query: pagination.query,
            }),
        );
        const endURL = `?search=${pagination.query}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
        if (pageLocation.search !== endURL) {
            navigate(`${pageLocation.pathname}${endURL}`);
        }
    };

    useEffect(() => {
        // console.log(pagination)
        getUsersFromProps();
    }, [pagination]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getUsersFromProps();
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [pagination.query])

    useEffect(() => {
        const params = new URLSearchParams(pageLocation.search);
        const page = params.get('page');
        const sortParam = params.get(SORT);
        let query = params.get('search');
        query = query ? query : '';
        if (page && sortParam) {
            const sortSplit = sortParam.split(',');
            setPagination({
                ...pagination,
                activePage: +page,
                sort: sortSplit[0],
                order: sortSplit[1],
                query: query ,
            });

        }
    }, [pageLocation.search]);

    const sort = (p: string) => () =>
        setPagination({
            ...pagination,
            order: pagination.order === ASC ? DESC : ASC,
            sort: p,
        });

    const handlePagination = (currentPage: number) =>
        setPagination({
            ...pagination,
            activePage: currentPage,
        });

    const users = useAppSelector(state => state.userManagement.users);
    const totalItems = useAppSelector(state => state.userManagement.totalItems);
    const loading = useAppSelector(state => state.userManagement.loading);
    const getSortIconByFieldName = (fieldName: string) => {
        const sortFieldName = pagination.sort;
        const order = pagination.order;
        if (sortFieldName !== fieldName) {
            return 'ChevronUpDownIcon';
        } else {
            return order === ASC ? "ChevronUpIcon" : "ChevronDownIcon";
        }
    };

    return (
        <div className={"container"}>
            <div className={`nc-PageAbout overflow-hidden relative`}>
                <div className="ml-4 flex flex-1 flex-col relative overflow-x-auto">
                    <h2 className={"text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100"}>
                        <Translate contentKey="userManagement.home.title"></Translate>
                    </h2>
                    <div className="pb-4 bg-white dark:bg-gray-900">
                        <label htmlFor="table-search" className="sr-only">{translate("userManagement.search")}</label>
                        <div className="relative mt-1">
                            <input type="text" id="table-search"
                                   onChange={(e) => {
                                       setPagination({...pagination, query: e.target.value})
                                   }}
                                   value={pagination.query}
                                   className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg 
                                   w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                                   ${loading ? 'disabled' : ''}`}
                                   placeholder={translate("userManagement.search")}/>
                        </div>
                    </div>

                    <table
                        className={`w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ${loading ? 'disabled' : ''}`}>
                        <thead
                            className={"text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                        <tr>
                            <th scope="col" className={"px-6 py-3 hand"} onClick={sort('login')}>
                                <div className={"flex items-center"}>
                                    <Translate contentKey="userManagement.login"></Translate>
                                    <DynamicHeroIcon icon={getSortIconByFieldName('login')}/>
                                </div>
                            </th>
                            <th scope="col" className={"px-6 py-3 hand"} onClick={sort('createdDate')}>
                                <div className={"flex items-center"}>
                                    <Translate contentKey="userManagement.createdDate"></Translate>
                                    <DynamicHeroIcon icon={getSortIconByFieldName('createdDate')}/>
                                </div>
                            </th>
                            <th scope="col" className={"px-6 py-3 hand"} onClick={sort('login')}>
                                <div className={"flex items-center"}>

                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user: IUser, i: number) => (
                            <tr className={"bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}
                                id={user.login} key={`user-${i}`}>
                                <td className={"px-6 py-4"}>{user.login}</td>
                                <td className={"px-6 py-4"}><DateFormat
                                    date={user.createdDate ? user.createdDate : undefined}/></td>
                                <td className={"px-6 py-4 la-pull-right"}>
                                    <div className={"flex items-center"}>
                                        <Link
                                            to={"/account"}
                                            onClick={() => {
                                                dispatch(impersonateUser(user.id));
                                            }}>
                                            <DynamicHeroIcon icon={"UserIcon"}></DynamicHeroIcon>
                                        </Link>
                                    </div>

                                </td>
                            </tr>

                        ))}
                        </tbody>
                    </table>

                    {totalItems ? (
                        <div className={users?.length > 0 ? '' : 'd-none'}>
                            <div className="justify-content-center d-flex">
                                <Pagination
                                    activePage={pagination.activePage}
                                    onSelect={handlePagination}
                                    maxButtons={3}
                                    itemsPerPage={pagination.itemsPerPage}
                                    totalItems={totalItems}
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserManagement;
