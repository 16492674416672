import React, {useEffect, useRef} from "react";

import {translate} from "react-jhipster";
import {useNavigate} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {IQRNotify} from "../shared/model/qr-notify.model";
import {notifyQROnboard} from "./reducers/qr-onboard-management.reducer";

const QR = () => {

    const dispatch = useAppDispatch();
    const checkerInitializer = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams<{ id: string }>();
    const notifyQR: IQRNotify = useAppSelector(state => state.qrOnboardManagement.notifyQROnboard);

    useEffect(() => {
        if (!checkerInitializer.current) {
            checkerInitializer.current = true;
            if (id != null) {
                dispatch(notifyQROnboard(id));
            }
            setTimeout(() => {
                navigate('/');
            }, 10 * 1000)
        }
    }, [location]);

    return (
        <div className="container">
            <div className="mt-12 mb-12">
                <div>
                    <div className="container">
                        <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                            <div
                                className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                <div
                                    className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                    <div className={`w-full`}>
                                        <div
                                            className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">
                                            <div className="relative">
                                                <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                    {translate("qr-init-page.title")}
                                                </h2>
                                                <span
                                                    className="block mt-6 text-slate-500 dark:text-slate-400">
                                            {translate("qr-init-page.text1", {email: notifyQR.email})}

                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default QR;
