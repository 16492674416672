import React, {useEffect, useRef} from "react";
import {useAppState} from "../config/AppStateContext";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import PlanDetails from "../../shared/components/PlanDetails";
import {listSubscriber, reset} from "../reducers/subscriber.reducer";
import {ISubscriber} from "../model/subscriber.model";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import AddButton from "../../shared/components/Button/AddButton";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import {translate} from "react-jhipster";

export let globalSubscribers: ISubscriber[] = [];

const AccountOrder = () => {
    const {state} = useAppState();
    let dispatch = useAppDispatch();
    let tenant = {};
    const initialized = useRef(false);
    const checkerInitializer = useRef(false);
    const subscribers: ISubscriber[] = useAppSelector(state => state.subscriber.subscribers);
    const isSubscribersLoading: boolean = useAppSelector(state => state.subscriber.loading);

    const navigate = useNavigate();
    const location = useLocation();
    const refreshing = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            tenant = state.tenant;
            dispatch(reset())
            dispatch(listSubscriber());
        }
        globalSubscribers = subscribers;
        Object.freeze(globalSubscribers);
    }, [state.tenant, dispatch, tenant, subscribers]);

    const wait = (delay: number) => {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }


    //@ts-ignore
    const fetchRetry = (url: string, delay: number, tries: number) => {
        // @ts-ignore
        const onError = (err) => {
            tries = tries - 1;
            if (!tries) {
                throw err;
            }
            return wait(delay).then(() => fetchRetry(url, delay, tries));
        }
//@ts-ignore
        const onSucces = (response) => {
            // console.log(response.data, globalSubscribers)
            if (response.data.length > globalSubscribers.length) {
                dispatch(listSubscriber())
            }
            tries = tries - 1;
            if (!tries) {
                return;
            }
            return wait(delay).then(() => fetchRetry(url, delay, tries));
        }

        return axios.get(url).then(onSucces).catch(onError);
    }

    useEffect(() => {
        if (!checkerInitializer.current) {
            checkerInitializer.current = true;
        }
    }, [location, location.search, globalSubscribers, fetchRetry]);

    return (
        <div>
            <div className="">
                {/* HEADING */}
                {subscribers.length > 0 ?
                    <div>
                        {subscribers.map((subscriber: ISubscriber, index: number) => (
                                <PlanDetails key={index} subscriber={subscriber} reload={!isSubscribersLoading}/>
                            )
                        )}
                    </div>
                    :
                    // if refreshing.current is true render refreshing div
                    refreshing.current ?
                        <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                            <LoadingSpinner/>

                            <div
                                className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                <div
                                    className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                    <div className={`w-full`}>
                                        <div
                                            className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                            <div className="relative">

                                                <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                    {translate("global.text.provision-wait")}
                                                </h2>
                                                <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                            {translate("global.text.provision-wait-details")} <a
                                                    className={"underline"}
                                                    href={'#'}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        navigate("/account-plans");
                                                    }}> {translate("global.text.provision-wait-refresh")}</a>.
                                        </span>

                                                <div className="flex space-x-2 mt-6 mb-6">
                                                    <ButtonPrimary
                                                        href="/"
                                                        className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                                    >
                                                        {translate("global.destination-button")}
                                                    </ButtonPrimary>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        :
                        isSubscribersLoading ?
                            <LoadingSpinner/>
                            :
                            // if refreshing.current is false render normal div
                            <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                                <div
                                    className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                    <div
                                        className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                        <div className={`w-full`}>
                                            <div
                                                className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                                <div className="relative">

                                                    <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                        {translate("global.destinations")}
                                                    </h2>
                                                    <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                            {translate("global.destination-details")} <a
                                                        className={"underline"}
                                                        href={'#'}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            navigate("/account-voucher");
                                                        }}>{translate("global.redeem")}</a>.
                                        </span>

                                                    <div className="flex space-x-2 mt-6 mb-6">
                                                        <ButtonPrimary
                                                            href="/"
                                                            className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                                        >
                                                            {translate("global.destination-button")}
                                                        </ButtonPrimary>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                }

                <AddButton/>

            </div>
        </div>
    );
};

export default AccountOrder;
