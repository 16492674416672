import React, {FC, useEffect, useState} from "react";
import {Switch} from "@headlessui/react";
import Label from "../Label/Label";
import {useAppState} from "../../../app/config/AppStateContext";
import ModalViewLegal from "../../../app/checkout/ModalViewLegal";
import {set} from "lodash";
import {translate} from "react-jhipster";

export interface MySwitchProps {
    enabled?: boolean;
    label?: string;
    desc?: string;
    className?: string;
    onChange?: (enabled: boolean) => void;
    disabled?: boolean;
}

const ESimSwitchWithLegal: FC<MySwitchProps> = ({
                                                    enabled = false,
                                                    label = "Put on sale",
                                                    desc = "You’ll receive bids on this item",
                                                    className = "",
                                                    onChange,
                                                    disabled = false,
                                                }) => {
    const {state} = useAppState();
    const [enabledState, setEnabledState] = useState(false);
    const [disabledState, setDisabledState] = useState(false);
    const [showModalViewLegal, setShowModalViewLegal] = useState(false);
    const [legalValue, setLegalValue] = useState<string>("state.tenant.documents.termsAndConditions" || "");


    useEffect(() => {
        setEnabledState(enabled);
        setDisabledState(disabled);
    }, [enabled, disabled]);

    return (
        <div className={`MySwitch flex fle justify-between items-center space-x-2 ${className}`}>
            <div>
                <Label>
                    {translate("order.terms-conditions")}

                </Label>
                <p className="text-neutral-500 dark:text-neutral-400  text-xs  w-1/2">

                    {translate("order.tc-1")} {" "}
                    <a
                        className={"underline"}
                        href={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            setLegalValue("state.tenant.documents.termsAndConditions");
                            setShowModalViewLegal(true);

                        }}
                    >
                        {translate("order.tc-2")}
                    </a>
                    {" "} {translate("order.tc-3")}{" "}
                    <a
                        className={"underline"}
                        href={"#"}
                        onClick={(e) => {
                            e.preventDefault();
                            setLegalValue("state.tenant.documents.privacyPolicy");
                            setShowModalViewLegal(true);

                        }}
                    >
                        {translate("order.tc-4")}
                    </a>

                </p>
            </div>
            <Switch
                checked={enabledState}
                onChange={(e: boolean) => {
                    setEnabledState(e);
                    onChange && onChange(e);
                }}
                className={`${enabledState ? "bg-teal-700" : "bg-neutral-400 dark:bg-neutral-6000"} ${
                    disabledState ? "disabled" : ""
                } relative inline-flex flex-shrink-0 h-8 w-[68px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                style={{
                    backgroundColor: state.tenant && enabledState ? state.tenant.primaryColor : "",
                }}
            >
                <span className="sr-only">{label}</span>
                <span
                    aria-hidden="true"
                    className={`${enabledState ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
            </Switch>

            <ModalViewLegal
                show={showModalViewLegal}
                onCloseModalViewLegal={() => setShowModalViewLegal(false)}
                value={legalValue}
            />
        </div>
    );
};

export default ESimSwitchWithLegal;
