// AppStateContext.js

import React, {createContext, useContext, useEffect, useReducer, useRef} from 'react';
import axios from "axios";

// Define your initial state and reducer function
const initialState = {
    tenant: null,
};

const appStateReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_TENANT':
            return {...state, tenant: action.payload};
        default:
            return state;
    }
};

// Create the context
const AppStateContext = createContext(null);

// Create a provider component
export const AppStateProvider = ({children}: any) => {
        const [state, dispatch] = useReducer(appStateReducer, initialState);
        const initialized = useRef(false)

        const fetchTenant = async () => {
            try {
                axios.get('/api/tenant')
                    .then(value => {
                        dispatch({type: 'SET_TENANT', payload: value.data});
                    }).catch(error => {
                    // console.log(error);
                })
            } catch (error) {
                console.error('Error fetching tenant data:', error);
            }
        };

        // Fetch the tenant data on app initialization
        useEffect(() => {
            if (!initialized.current) {
                initialized.current = true;
                fetchTenant();
            }
        }, []);

        if (!initialized.current) {
            initialized.current = true;
            fetchTenant();
        }

        return (
            // @ts-ignore
            <AppStateContext.Provider value={{state, dispatch}}>
                {children}
            </AppStateContext.Provider>
        );
    }
;

// Create a custom hook to access the context
export const useAppState = () => {
    const context: any = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};
