import {Storage} from "../../app/reducers/storage-util";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import cookieImg from "../img/cookie.svg";
import {Image} from "@chakra-ui/react";
import React, {useState} from "react";
import {translate, Translate} from "react-jhipster";
import ModalViewLegal from "../../app/checkout/ModalViewLegal";

const Cookie = () => {
    const [showCookies, setShowCookies] = useState<boolean>(Storage.local.get('persist:root', true));
    const [allowAnalytics, setAllowAnalytics] = useState<boolean>(Storage.local.get('cookie:analytics', true));
    const [allowMarketing, setAllowMarketing] = useState<boolean>(Storage.local.get('persist:marketing', true));
    const [showModalViewLegal, setShowModalViewLegal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const acceptCookie = () => {
        Storage.local.set('persist:root', false)
        setShowCookies(false);
    }

    return (
        <>{showCookies ? <div className="fixed bottom-0 right-0 mb-4 mr-4 ml-4 xl:w-1/4 lg:w-1/3 z-max">
            <div className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                        <Image src={cookieImg} className="h-6 w-6 mr-2"></Image>
                        <span className="text-gray-700 font-bold text-sm"><Translate
                            contentKey={"cookie.title"}/></span>
                    </div>
                </div>
                <p className="text-gray-600 text-sm pb-2">
                    <Translate contentKey={"cookie.description"}/>
                    <button className={"-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"}
                            onClick={() => {
                                setShowModalViewLegal(true);
                            }}>
                        <Translate contentKey={"cookie.link-page"}/></button>
                    .
                    {showDetails ?
                        <>
                            <div className="flex items-center pt-1">
                                <label
                                    className="w-full pt-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >{translate("cookie.necessary-cookies")}</label>
                                <input type="checkbox" checked={true} disabled={true} className={`disabled`}/>
                            </div>
                            <div className="flex items-center pt-1">
                                <label
                                    className="w-full pt-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{translate("cookie.analytics-cookies")}</label>
                                <input type="checkbox" checked={allowAnalytics} onClick={() => {
                                    setAllowAnalytics(!allowAnalytics);
                                    Storage.local.set('cookie:analytics', !allowAnalytics);
                                }}/>

                            </div>
                            <div className="flex items-center pt-1">
                                <label
                                    className="w-full pt-1 pb-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{translate("cookie.marketing-cookies")}</label>
                                <input type="checkbox" checked={allowMarketing} onClick={() => {
                                    setAllowMarketing(!allowMarketing);
                                    Storage.local.set('persist:marketing', !allowMarketing);
                                }}/>
                            </div>
                        </> : null}
                </p>
                <div className={'flex items-center justify-between'}>
                    <ButtonPrimary onClick={acceptCookie}
                                   className="buyCardButton w-50 bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white">
                        <Translate contentKey={"cookie.accept-button"}/>
                    </ButtonPrimary>
                    <p onClick={() => setShowDetails(true)} className={`${showDetails ? 'hidden' : ''}`}>
                        <p className="underline cursor-pointer">{translate("cookie.cookie-settings")}</p>
                    </p>

                    <p onClick={() => setShowDetails(false)} className={`${!showDetails ? 'hidden' : ''}`}>
                        <p className="underline cursor-pointer">{translate("cookie.hide-details")}</p>
                    </p>
                </div>
            </div>
        </div> : null}

            <ModalViewLegal
                show={showModalViewLegal}
                onCloseModalViewLegal={() => setShowModalViewLegal(false)}
                value={"state.tenant.documents.cookiePolicy"}
            />
        </>
    )
}

export default Cookie;
