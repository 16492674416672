import React, {FC} from "react";
import Heading from "../shared/components/Heading/Heading";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import FAQOSSelector from "./faq/esim/FAQOSSelector";
import FAQAccordionInfo from "./faq/esim/FAQAccordionInfo";

export interface Statistic {
    id: string;
    heading: string;
    subHeading: string;
}

export interface SectionStatisticProps {
    className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({className = ""}) => {
    return (
        <div className={`nc-SectionStatistic relative ${className}`}><FAQAccordionInfo/></div>
    );
};

export default SectionStatistic;
