import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import googleSvg from "../shared/img/socials/google.svg";
import {isEmail, translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppState} from "./config/AppStateContext";
import {Alert} from "../shared/components/Alert/Alert";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {googleLogin, login} from "./reducers/authentication";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import {Link, useLocation} from "react-router-dom";

const loginSocials = [
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

const PageLogin = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const loading = useAppSelector(state => state.authentication.loading);
    const loginError = useAppSelector(state => state.authentication.loginError);
    const {state} = useAppState();
    const location = useLocation();
    const navigate = useNavigate();

    // @ts-ignore
    function handleSignUp({email, password, rememberMe}: { email: string, password: string }) {
        dispatch(login(email, password, rememberMe));
    }


    useEffect(() => {
        if (isAuthenticated) {
            if (location.state !== null && location.state.from !== undefined) {
                navigate(location.state.from);
                return;
            }
            navigate('/account');
        }
    }, [location, navigate, isAuthenticated, state])

    const handleGoogleLogin = (response: any) => {
        dispatch(googleLogin(response.credential));
    }


    return (
        <div className={`nc-PageLogin`} data-nc-id="PageLogin">
            {loading ? '' :
                <div className="container">
                    <h2 className="my-12 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        {translate("login.title")}
                    </h2>
                    <div className="max-w-md mx-auto space-y-6">

                        {loginError ? (
                            <Alert type={'error'}>
                                {translate("login.error.fail-login")}
                            </Alert>
                        ) : null}

                        <ValidatedForm
                            // @ts-ignore
                            onSubmit={handleSignUp}
                            className="grid grid-cols-1 gap-6">
                            <ValidatedField
                                name="email"
                                label={translate("global.email-label")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                // @ts-ignore
                                placeholder={translate("global.email-placeholder")}
                                type="email"
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                validate={{
                                    required: {value: true, message: translate("global.error.email-required")},
                                    minLength: {
                                        value: 5,
                                        message: translate("global.error.email-length-min")
                                    },
                                    maxLength: {value: 50, message: translate("global.error.email-length-max")},
                                    validate: v => isEmail(v) || translate("global.error.email-invalid"),
                                }}
                                data-cy="email"
                            />

                            <ValidatedField
                                name="password"
                                // @ts-ignore
                                type="password"
                                label={translate("global.password-label")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                placeholder={translate("global.password-placeholder")}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                required
                                data-cy="password"
                                validate={{required: translate("global.error.not-empty-password")}}
                            />

                            {/*<div className="flex justify-between items-center float-right w-full justify-end">*/}
                            {/*    <ValidatedField*/}
                            {/*        name="rememberMe"*/}
                            {/*        label="Remember me"*/}
                            {/*        // @ts-ignore*/}
                            {/*        type="checkbox"*/}
                            {/*        data-cy="rememberMe"*/}
                            {/*        labelClass={'text-neutral-800 dark:text-neutral-200 pr-2 sm:pr-3.5'}*/}
                            {/*        inputClass={'focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-6 h-6'}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <ButtonPrimary
                                className="buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                type="submit">{translate("login.submit-button")}</ButtonPrimary>

                            <label className="block">
                            <span
                                className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 la-pull-right">
                              <Link to="/forgot-pass" className="text-sm text-green-600"
                                    style={{color: state.tenant && state.tenant.secondaryColor ? state.tenant.secondaryColor : "black"}}

                              >
                                {translate("login.recover-password")}
                              </Link>
                            </span>
                                <span
                                    className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 la-pull-left">
                              <Link to="/signup" className="text-sm text-green-600"
                                    style={{color: state.tenant && state.tenant.secondaryColor ? state.tenant.secondaryColor : "black"}}
                              >
                                {translate("login.create-account")}
                              </Link>
                            </span>
                            </label>

                        </ValidatedForm>

                        <span className="block text-center text-neutral-700 dark:text-neutral-300">

<div className="relative text-center"><span
    className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">{translate("login.separator")}</span><div
    className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div></div>

                    </span>
                        <span className="pb-12 block text-center text-neutral-700 dark:text-neutral-300">

                        {state.tenant != null && state.tenant.googleOAuthClientId ?
                            <GoogleOAuthProvider
                                clientId={state.tenant.googleOAuthClientId}>
                                <GoogleLogin onSuccess={handleGoogleLogin} type={"standard"}
                                             text={"continue_with"} size={"large"} theme={"filled_blue"}
                                             shape={"circle"}></GoogleLogin>
                            </GoogleOAuthProvider> : <></>
                        }
                    </span>
                    </div>
                </div>
            }
        </div>
    )
};

export default PageLogin;
