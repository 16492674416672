import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import setupAxiosInterceptors from "./app/config/axios-interceptor";
import {bindActionCreators} from "@reduxjs/toolkit";
import getStore from "./app/config/localStorage";
import {clearAuthentication} from "./app/reducers/authentication";
import {AppStateProvider, useAppState} from "./app/config/AppStateContext";
import {Provider} from "react-redux";
import ErrorBoundary from "./shared/error/error-boundary";
import {registerLocale} from "./app/config/translation";
import {updateLocale} from "./app/reducers/locale";

const store = getStore();


const actions = bindActionCreators({clearAuthentication}, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

const AppWrapper = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <AppStateProvider>
                    <Provider store={getStore()}>
                        <InnerComponent/>
                    </Provider>
                </AppStateProvider>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

const InnerComponent = () => {
    const {state} = useAppState();
    if (state.tenant) {
        registerLocale(store, state.tenant.locale);

    }
    return <App/>;
};


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<AppWrapper/>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
