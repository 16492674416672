// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}
.point:last-child {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/password/password-strength-bar.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,eAAA;EACA,gBAAA;EACA,SAAA;EACA,iBAAA;EACA,UAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,WAAA;AACF;AAAE;EACE,oBAAA;AAEJ","sourcesContent":["/* ==========================================================================\nstart Password strength bar style\n========================================================================== */\nul#strength {\n  display: inline;\n  list-style: none;\n  margin: 0;\n  margin-left: 15px;\n  padding: 0;\n  vertical-align: 2px;\n}\n\n.point {\n  background: #ddd;\n  border-radius: 2px;\n  display: inline-block;\n  height: 5px;\n  margin-right: 1px;\n  width: 20px;\n  &:last-child {\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
