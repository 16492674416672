import Button, {IButtonProps} from "./Button";
import React, {CSSProperties} from "react";

export interface IButtonPrimaryProps extends IButtonProps {
    style?: CSSProperties | undefined;
}

const ButtonPrimary: React.FC<IButtonPrimaryProps> = ({
                                                          className = "",
                                                          style,
                                                          ...args

                                                      }) => {
    return (
        <Button
            className={`ttnc-ButtonPrimary disabled:bg-opacity-50 shadow-xl ${className}`}
            {...args}
            style={style}
        />
    );
};

export default ButtonPrimary;
