import {FC} from 'react'
import {APP_TIMESTAMP_FORMAT} from "../../app/config/constants";
import dayjs from "dayjs";

export interface DateFormatProps {
    date?: Date;
}

const DateFormat: FC<DateFormatProps> = (props) => {

    return (<>
        {dayjs(props.date)
            .format(APP_TIMESTAMP_FORMAT)}
    </>)
}

export default DateFormat