import React from 'react';
import ButtonPrimary from "../components/Button/ButtonPrimary";
import I404Png from "../../shared/img/404.png";

const PageNotFound = () => {
    return (
        <div className="nc-Page404">
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                <header className="text-center max-w-2xl mx-auto space-y-2">
                    <img src={I404Png} className={'object-cover w-full h-full'} alt="not-found"/>
                    <span
                        className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
        </span>
                    <div className="pt-8">
                        <ButtonPrimary className={'w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800'}

                                       href="/">Destinations</ButtonPrimary>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default PageNotFound;
