import React, {useEffect, useRef} from 'react';
import "./globals.css";
import "./styles/layout.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "@fontsource/poppins";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import Header from "./shared/header/Header";
import ErrorBoundary from "./shared/error/error-boundary";
import {BrowserRouter, Route} from "react-router-dom";
import ErrorBoundaryRoutes from "./shared/error/error-boundary-routes";
import Home from "./app/Home";

import Footer from "./shared/footer/Footer";
import PageNotFound from "./shared/error/page-not-found";
import FirstUse from "./app/faq/first-use/page";
import About from "./app/About";
import PageSearch from "./app/faq/esim/page";
import ContactUs from "./app/ContactUs";
import Login from "./app/Login";
import {useAppDispatch, useAppSelector} from "./app/config/localStorage";
import ForgotPassword from "./app/ForgotPassword";
import SignUp from "./app/SignUp";
import Account from "./app/account/Account";
import Activate from "./app/account/Activate";
import ForgotPasswordFinish from "./app/ForgotPasswordFinish";
import Checkout from "./app/checkout/Checkout";
import {getSession} from "./app/reducers/authentication";
import {findCountry} from "./app/reducers/country";
import {useAppState} from "./app/config/AppStateContext";
import {Toaster} from "react-hot-toast";
import TermsAndConditions from "./app/TermsAndConditions";
import CookiePolicy from "./app/CookiePolicy";
import PrivacyPolicy from "./app/PrivacyPolicy";
import CompatibleDevices from "./app/CompatibleDevices";
import Voucher from "./app/account/AccountVoucher";
import QuickOnboarding from "./app/QuickOnboarding";
import UserManagement from "./app/administration/user-management/user-management";
import PrivateRoute from "./shared/components/PrivateRoute/private-route";
import {AUTHORITIES} from "./app/config/constants";
import Cookie from "./shared/header/Cookie";
import QROnboard from "./app/qr/QROnboard";
import OnboardingManagement from "./app/administration/onboarding/onboarding-management";
import FullQROnboardManagementUpdate from "./app/administration/onboarding/onboard-management-update";
import OnboardManagementDeleteDialog from "./app/administration/onboarding/onboard-management-delete-dialog";
import OnboardManagementNotifyDialog from "./app/administration/onboarding/onboard-management-notify-dialog";
import QR from "./app/QR";
import OnboardManagementQRViewDialog from "./app/administration/onboarding/onboard-management-qr-view-dialog";

function App() {
    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const {state} = useAppState();
    const currentLocale = useAppSelector(state => state.locale.currentLocale);
    const loadedLocales = useAppSelector(state => state.locale.loadedLocales);


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            dispatch(getSession());
            dispatch(findCountry(''));

        }

    }, [dispatch, state.tenant]);


    return (
        <>
            {state.tenant !== null && loadedLocales ?
                <BrowserRouter>
                    <Header currentLocale={currentLocale}/>
                    <Toaster/>
                    <ErrorBoundary>
                        <ErrorBoundaryRoutes>
                            <Route index element={<Home/>}/>
                            <Route path="/plan/:id" element={<Home/>}/>
                            <Route path="/esim" element={<About/>}/>
                            <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                            <Route path="/cookie-policy" element={<CookiePolicy/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/compatible-devices" element={<CompatibleDevices/>}/>
                            <Route path="/quick-onboarding" element={<QuickOnboarding/>}/>
                            <Route path="/qr/:id" element={<QR/>}/>
                            <Route path="/account-voucher" element={<Voucher/>}/>
                            <Route path="/faq/first-use" element={<FirstUse/>}/>
                            <Route path="/faq/esim" element={<PageSearch/>}/>
                            <Route path="/contact" element={<ContactUs/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Login/>}/>
                            <Route path="/signup" element={<SignUp/>}/>
                            <Route path="/forgot-pass" element={<ForgotPassword/>}/>

                            <Route path="/account"
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Account/>
                                   </PrivateRoute>}/>
                            <Route path="/account-password"
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Account/>
                                   </PrivateRoute>}/>
                            <Route path="/account-order"
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Account/>
                                   </PrivateRoute>}/>
                            <Route path="/account-plans"
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Account/>
                                   </PrivateRoute>}/>
                            <Route path="/account-world-map"
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Account/>
                                   </PrivateRoute>}/>

                            <Route path="/activate" element={<Activate/>}/>
                            <Route path="/quick-onboarding/:id" element={<QROnboard/>}/>
                            <Route path="/reset-password-finish" element={<ForgotPasswordFinish/>}/>

                            <Route path="/checkout/:id"
                                // @ts-ignore
                                   element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}> <Checkout/>
                                   </PrivateRoute>
                                   }/>

                            <Route path="/admin/onboarding/*" element={
                                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
                                <ErrorBoundaryRoutes>
                                    <Route index element={<OnboardingManagement/>}/>
                                    <Route path="new" element={<FullQROnboardManagementUpdate />} />
                                    <Route path=":id">
                                        <Route index element={<FullQROnboardManagementUpdate/>}/>
                                        <Route path="edit" element={<FullQROnboardManagementUpdate/>}/>
                                        <Route path="delete" element={<OnboardManagementDeleteDialog/>}/>
                                        <Route path="notify" element={<OnboardManagementNotifyDialog/>}/>
                                        <Route path="view" element={<OnboardManagementQRViewDialog/>}/>
                                    </Route>
                                </ErrorBoundaryRoutes>
                                </PrivateRoute>
                            }/>

                            <Route
                                path="/admin/users"
                                element={
                                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
                                        <UserManagement/>
                                    </PrivateRoute>
                                }
                            />
                            <Route path="*" element={<PageNotFound/>}/>
                        </ErrorBoundaryRoutes>
                    </ErrorBoundary>
                    <Footer/>
                    <Cookie/>
                </BrowserRouter> : null}
        </>

    );
}

export default App;
