import React, {useState} from "react";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import {useAppState} from "./config/AppStateContext";
import Select from "react-select/async";
import {components} from "react-select";
import {translate} from "react-jhipster";

export const DropdownIndicator = (props: any) => {
    console.log(props);
    const isOptionsEmpty = props.options.length === 0;
    //const isOptionsEmpty = props.hasValue === false;

    return (
        <components.DropdownIndicator {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {isOptionsEmpty ? (
                    <path
                        d="M6 6L18 18M6 18L18 6"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                ) : (
                    <path
                        d="M6 12L10 16L18 8"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                )}
            </svg>
        </components.DropdownIndicator>
    );
};

const CompatibleDevices = ({}) => {
    const {state} = useAppState();
    const [inputValue, setInputValue] = useState('');
    const [apiResults, setApiResults] = useState<any[]>([]);
    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {

            console.log(inputValue);
            const response = fetch(`/api/brands?keyword=${inputValue}`).then(response => response.json()).then(data => {
                const devices: any[] | PromiseLike<any[]> = [];

                data.map((result: any) => {
                    if (result.devices != undefined) {
                        result.devices.map((device: any) => {
                            if (device.models != undefined) {
                                device.models.map((model: any) => {
                                    const label = `${result.brand} ${device.device} ${model.model}`;
                                    const containsAllWords = inputValue.split(' ')
                                        .every(word => label.toLowerCase().includes(word.toLowerCase()));
                                    if (containsAllWords) {
                                        devices.push({
                                            value: result.id,
                                            label: label
                                        });
                                    }
                                })
                            }
                        })
                    }
                });

                resolve(devices);

            });
        });

    return (
        <div className={`nc-PageAbout overflow-hidden min-h-75 relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>
            <div className="container mb-12">
                <div className="">
                    {/* HEADING */}
                    <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                        <div
                            className="border border-slate-200 dark:border-slate-700 rounded-lg z-0 ">
                            <div
                                className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                <div className={`w-full`}>
                                    <div
                                        className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                        <div className="relative">

                                            <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                {translate("device-compatibility.title")}
                                            </h2>
                                            <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                                {translate("device-compatibility.description")}
                                            </span>

                                            <form className="mt-10 relative max-w-sm">
                                                <Select
                                                    isMulti={false}
                                                    name={'name'}
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={promiseOptions}
                                                    components={{DropdownIndicator}}
                                                    defaultValue={null}
                                                    placeholder={translate("device-compatibility.input-placeholder")}
                                                    minMenuHeight={1000}
                                                />
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompatibleDevices;
